// import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
// import { Helmet } from 'react-helmet-async';
import {
    Avatar,
    Box,
    Container,
    Stack,
    SvgIcon,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import { subDays, subHours, subMinutes } from 'date-fns';
import ShoppingBagIcon from '@heroicons/react/24/solid/ShoppingBagIcon';
import ShoppingCartIcon from '@heroicons/react/24/solid/ShoppingCartIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
import { OverviewKpi } from '../../sections/overview/overview-kpi';
import { OverviewLatestCustomers } from '../../sections/overview/overview-latest-customers';
import { OverviewSummary } from '../../sections/overview/overview-summary';

import PropTypes from 'prop-types';
import { Footer } from './footer';
// import { SideNav } from './SideNav';
import { TopNav } from './TopNav';

const SIDE_NAV_WIDTH = 73;
const TOP_NAV_HEIGHT = 64;

const now = new Date();

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: TOP_NAV_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

export const Layout = (props) => {
  // const [slug, setSlug] = React.useState(null);
  // console.log('slug1', window.localStorage.getItem('account'));
  // setSlug(window.localStorage.getItem('account'));
  // debugger;
  // console.log('slug', slug);
  // debugger;
  // if(!slug) {
  //   window.location.href = "/404";
  // }
  // const { children } = props;

  return (
    <>
      <TopNav />
      {/* <SideNav /> */}
      <LayoutRoot>
        <LayoutContainer>
            <>
            {/* <Helmet>
                <title>
                    Overview | Carpatin Free
                </title>
            </Helmet> */}
            <Box
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
                >
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                    <div>
                        <Typography variant="h4">
                        Dashboard
                        </Typography>
                    </div>
                    <div>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <ShoppingBagIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Applications'
                            value='5610'
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <ShoppingCartIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Products'
                            value='23'
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <CurrencyDollarIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Transactions'
                            value='1942'
                          />
                        </Grid>
                        <Grid xs={12}>
                          <OverviewKpi
                            chartSeries={[
                              {
                                data: [0, 20, 40, 30, 30, 44, 90],
                                name: 'Revenue'
                              }
                            ]}
                            stats={[
                              {
                                label: 'Revenue',
                                value: '$4,800.00'
                              },
                              {
                                label: 'NET',
                                value: '$4,900,24'
                              },
                              {
                                label: 'Pending orders',
                                value: '$1,600.50'
                              },
                              {
                                label: 'Due',
                                value: '$6,900.10'
                              },
                              {
                                label: 'Overdue',
                                value: '$6,500.80'
                              }
                            ]}
                          />
                        </Grid>
                        <Grid xs={12}>
                          <OverviewLatestCustomers
                            customers={[
                              {
                                id: 'a105ac46530704806ca58ede',
                                amountSpent: 684.45,
                                avatar: '/assets/avatars/avatar-fabiano-jorioz.jpg',
                                createdAt: subDays(subHours(subMinutes(now, 7), 3), 2).getTime(),
                                isOnboarded: true,
                                name: 'Fabiano Jorioz',
                                orders: 2
                              },
                              {
                                id: '126ed71fc9cbfabc601c56c5',
                                amountSpent: 0,
                                avatar: '/assets/avatars/avatar-meggie-heinonen.jpg',
                                createdAt: subDays(subHours(subMinutes(now, 7), 3), 2).getTime(),
                                isOnboarded: false,
                                name: 'Meggie Heinonen',
                                orders: 0
                              },
                              {
                                id: 'aafaeb0545357922aff32a7b',
                                amountSpent: 32.25,
                                avatar: '/assets/avatars/avatar-sean-picott.jpg',
                                createdAt: subDays(subHours(subMinutes(now, 11), 2), 3).getTime(),
                                isOnboarded: true,
                                name: 'Sean Picott',
                                orders: 1
                              },
                              {
                                id: '16b526d9e0fefe53f7eba66b',
                                amountSpent: 0,
                                avatar: '/assets/avatars/avatar-bell-covely.jpg',
                                createdAt: subDays(subHours(subMinutes(now, 18), 9), 5).getTime(),
                                isOnboarded: true,
                                name: 'Bell Covely',
                                orders: 0
                              },
                              {
                                id: 'fe035356923629912236d9a2',
                                amountSpent: 125.70,
                                avatar: '/assets/avatars/avatar-giraud-lamlin.jpg',
                                createdAt: subDays(subHours(subMinutes(now, 19), 18), 7).getTime(),
                                isOnboarded: false,
                                name: 'Giraud Lamlin',
                                orders: 1
                              }
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    </Stack>
                </Container>
            </Box>
            </>
            {/* {children} */}
          <Footer />
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};