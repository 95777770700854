import * as React from 'react';

// import Box from '@mui/joy/Box';
// import Button from '@mui/joy/Button';
// import IconButton from '@mui/joy/IconButton';
// import Typography from '@mui/joy/Typography';
// import Sheet from '@mui/joy/Sheet';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';

/**
 * Design credit: https://flutter.dev/
 */

export default function GettingStarted() {
  // const [color, setColor] = React.useState('primary');
  return (
      <>
      
      </>
  );
}