import React, { useState } from 'react';
import { FaShareAlt, FaBookmark } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import BasicMenu from '../containers/common/Navigation';
// import AccountMenu from '../containers/common/AccountMenu';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import BasicTabs from '../containers/common/TabMenu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import WebsiteMenu from '../WebsiteMenu';
import Footer from '../Footer';
// import Footer2 from '../Footer2';
import logo from '../../freehire.png';

import Divider from '@mui/material/Divider';
// or
// import { Divider } from '@mui/material';
// import ProTip from '../../containers/common/ProTip';
// import Link from '../../containers/common/Link';
// import { Navigate } from 'react-router-dom';
// import Copyright from '../../containers/common/Copyright';

function SampleJob() {

    const jobDetails = {
        title: "Software Engineer",
        company: "ABC Tech",
        location: "New York, NY",
        description: "ABC Tech is seeking a talented Software Engineer to join our team. The ideal candidate will have experience with React.js, Node.js, and MongoDB.",
        responsibilities: ["Develop new features for our web applications", "Optimize existing codebase for performance", "Collaborate with cross-functional teams to define, design, and ship new features"],
        qualifications: ["Bachelor's degree in Computer Science or related field", "Experience with React.js, Node.js, and MongoDB", "Strong problem-solving skills"],
    };

    const handleApply = () => {
    // Logic to handle job application
    alert("You have applied for the job!");
    };

    const handleShare = () => {
    // Logic to handle job sharing
    alert("Job shared successfully!");
    };

    const handleBookmark = () => {
    // Logic to handle job bookmarking
    alert("Job bookmarked!");
    };

  return (
    <>
    <Container maxWidth="lg">
        <Box sx={{ my: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography component="h5">
            <img src={logo} style={{maxHeight: '35px'}} alt="" />
          </Typography>
          {/* <WebsiteMenu/> */}
        </Grid>
        {/* <Grid item xs={2}>
          <Tooltip title="Login">
              <Button variant="outlined">
                  Login
              </Button>
          </Tooltip>
        </Grid> */}
        </Grid>
        </Box>
        <Divider/>
      <Box sx={{ my: 2 }}>
        {/* <BasicMenu/> */}
        <Container maxWidth="md">
        <div className="job-page">
            <h1>{jobDetails.title}</h1>
            <h2>{jobDetails.company} - {jobDetails.location}</h2>
            
            <div>
                <h3>Description</h3>
                <p>{jobDetails.description}</p>
            </div>

            <div>
                <h3>Responsibilities</h3>
                <ul>
                {jobDetails.responsibilities.map((responsibility, index) => (
                    <li key={index}>{responsibility}</li>
                ))}
                </ul>
            </div>

            <div>
                <h3>Qualifications</h3>
                <ul>
                {jobDetails.qualifications.map((qualification, index) => (
                    <li key={index}>{qualification}</li>
                ))}
                </ul>
            </div>

            <div>
                <button onClick={handleApply}>Apply</button>
                <button onClick={handleShare}><FaShareAlt /> Share</button> {/* Include the share icon */}
                <button onClick={handleBookmark}><FaBookmark /> Bookmark</button> {/* Include the bookmark icon */}
            </div>
            </div>
        </Container>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default SampleJob;
