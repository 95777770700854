import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FaShareAlt, FaBookmark } from 'react-icons/fa'; // Import share and bookmark icons from Font Awesome
// import BasicMenu from '../containers/common/Navigation';
// import AccountMenu from '../containers/common/AccountMenu';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import BasicTabs from '../containers/common/TabMenu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import WebsiteMenu from '../WebsiteMenu';
import Footer from '../Footer';
import Footer2 from '../Footer2';

import Divider from '@mui/material/Divider';
// or
// import { Divider } from '@mui/material';
// import ProTip from '../../containers/common/ProTip';
// import Link from '../../containers/common/Link';
// import { Navigate } from 'react-router-dom';
// import Copyright from '../../containers/common/Copyright';
import logo from '../../freehire.png';

function TnC() {
  return (
    <>
    <Container maxWidth="lg">
        <Box sx={{ my: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography component="h5">
          <img src={logo} style={{maxHeight: '35px'}} alt="" />
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Tooltip title="Login">
              <Button variant="outlined">
                  Login
              </Button>
          </Tooltip>
        </Grid> */}
        </Grid>
        </Box>
        <Divider/>
      <Box sx={{ my: 4 }}>
        {/* <BasicMenu/> */}
        <Container maxWidth="md">
        <div className="terms-and-conditions">
          <h1>Terms and Conditions</h1>
          <p>Welcome to our Free Hire Portal/Marketplace! These Terms and Conditions govern your use of our platform, whether as a company, consultancy, recruiter, HR professional, or job seeker. By accessing or using our portal, you agree to comply with these Terms and Conditions. Please read them carefully before using our services.</p>

          <h2>1. Acceptance of Terms</h2>
          <p>By accessing or using our Free Hire Portal/Marketplace, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not access or use our platform.</p>

          <h2>2. Description of Services</h2>
          <p>Our portal provides a platform for companies, consultancies, recruiters, HR professionals, and job seekers to post job listings, track candidate profiles, and engage in the recruitment process. We operate on a freemium model, offering basic services for free and additional premium features for a fee.</p>

          <h2>3. User Accounts</h2>
          <ul>
            <li>To access certain features of our portal, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
            <li>You agree to provide accurate and complete information when creating your account and to update such information promptly if it changes.</li>
            <li>You may not share your account credentials with any third party or allow any third party to access your account.</li>
          </ul>

          {/* Other sections of terms and conditions */}
          <h2>4. Posting Jobs</h2>
          <ul>
            <li>Companies, consultancies, recruiters, and HR professionals may post job listings on our platform. You agree to provide accurate and truthful information in your job postings.</li>
            <li>You may not post any job listings that are misleading, fraudulent, or illegal.</li>
          </ul>

          <h2>5. Posting Jobs</h2>
          <ul>
            <li>Job seekers may create profiles on our platform to showcase their skills, experience, and qualifications to potential employers.</li>
            <li>Job seekers are responsible for the accuracy and completeness of the information provided in their profiles.</li>
            <li>By creating a profile, job seekers consent to their information being visible to companies, consultancies, recruiters, and HR professionals using our platform.</li>
          </ul>

          <h2>6. Payments</h2>
          <ul>
            <li>We offer premium features and services for a fee. Payment for these services will be collected through our online payment system.</li>
            <li>By purchasing premium features or services, you agree to pay the specified fees and abide by our refund policy.</li>
          </ul>

          <h2>7. Prohibited Conduct</h2>
          <ul>
            <li>You may not use our platform for any unlawful or prohibited purpose.</li>
            <li>You may not engage in any activity that disrupts or interferes with the operation of our platform.</li>
            <li>You may not attempt to gain unauthorized access to any part of our platform or to any other user's account.</li>
          </ul>

          <h2>8. Intellectual Property</h2>
          <ul>
            <li>All content and materials available on our platform, including but not limited to text, graphics, logos, images, and software, are the property of our company or our licensors and are protected by copyright and other intellectual property laws.</li>
            <li>You may not use, reproduce, or distribute any content from our platform without our prior written consent.</li>
          </ul>

          <h2>9. Disclaimer of Warranties</h2>
          <ul>
            <li>Our platform is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied.</li>
            <li>We do not warrant that our platform will be uninterrupted or error-free, or that any defects will be corrected.</li>
          </ul>

          <h2>10. Limitation of Liability</h2>
          <ul>
            <li>In no event shall we be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our platform.</li>
            <li>Our total liability to you for all claims arising out of or relating to these Terms and Conditions shall not exceed the amount paid by you, if any, for the use of our platform.</li>
          </ul>

          <h2>11. Indemnification</h2>
          <p>You agree to indemnify and hold harmless our company, its affiliates, officers, directors, employees, and agents from any and all claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, arising out of or in any way related to your use of our platform or violation of these Terms and Conditions.</p>

          <h2>12. Governing Law</h2>
          <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p>

          <h2>13. Changes to Terms and Conditions</h2>
          <p>
          We reserve the right to modify or update these Terms and Conditions at any time without prior notice. Your continued use of our platform after any such changes constitutes your acceptance of the new Terms and Conditions.
          </p>
          <br/>
          <p>
          By using our Free Hire Portal/Marketplace, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
          </p>
        </div>
          </Container>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default TnC;
