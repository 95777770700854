import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import features_list from '../constants/features';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQs() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {/* {features_list.map((feature) => ( */}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>About Note Creation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Note Creation: The app allows users to create new notes by providing a title and content. Users can easily jot down their thoughts or any information they want to remember.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* ))} */}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Organization and Categories:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Users can organize their notes efficiently by categorizing them into different folders or tags. This feature helps users find and manage related notes effortlessly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Editing and Formatting:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The notes app enables users to edit and format their text, such as applying styles (bold, italic, etc.) and adding bullet points or numbered lists.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Sync and Cloud Backup:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Modern notes apps often come with synchronization capabilities, allowing users to access their notes across multiple devices seamlessly. Cloud backup ensures that notes are securely stored and can be retrieved even if a device is lost or damaged.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
