import React, { useState } from 'react';
import { FaShareAlt, FaBookmark } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import BasicMenu from '../containers/common/Navigation';
// import AccountMenu from '../containers/common/AccountMenu';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import BasicTabs from '../containers/common/TabMenu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import WebsiteMenu from '../WebsiteMenu';
import Footer from '../Footer';
// import Footer2 from '../Footer2';
import logo from '../../freehire.png';

import Divider from '@mui/material/Divider';
// or
// import { Divider } from '@mui/material';
// import ProTip from '../../containers/common/ProTip';
// import Link from '../../containers/common/Link';
// import { Navigate } from 'react-router-dom';
// import Copyright from '../../containers/common/Copyright';

function JobListPage() {

    // State variables for filters
  const [locationFilter, setLocationFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [workTypeFilter, setWorkTypeFilter] = useState('');

  // Dummy job data
  const jobList = [
    {
      id: 1,
      title: "Software Engineer",
      company: "ABC Tech",
      location: "New York, NY",
      type: "Hybrid",
      workType: "Full-time",
      team: "Engineering",
      department: "Development",
    },
    {
      id: 2,
      title: "Marketing Specialist",
      company: "XYZ Corp",
      location: "London, UK",
      type: "Onsite",
      workType: "Part-time",
      team: "Marketing",
      department: "Marketing",
    },
    // Add more job data as needed
  ];

  // Filter function
  const filterJobs = (job) => {
    return (
      (locationFilter === '' || job.location.toLowerCase().includes(locationFilter.toLowerCase())) &&
      (typeFilter === '' || job.type === typeFilter) &&
      (workTypeFilter === '' || job.workType === workTypeFilter)
    );
  };

  return (
    <>
    <Container maxWidth="lg">
        <Box sx={{ my: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography component="h5">
            <img src={logo} style={{maxHeight: '35px'}} alt="" />
          </Typography>
          {/* <WebsiteMenu/> */}
        </Grid>
        </Grid>
        </Box>
        <Divider/>
      <Box sx={{ my: 2 }}>
        {/* <BasicMenu/> */}
        <Container maxWidth="md">
        <div className="job-list-page">
            {/* Filter section */}
            <div className="filters">
                <input
                type="text"
                placeholder="Location"
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
                />
                <select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
                <option value="">Type</option>
                <option value="Hybrid">Hybrid</option>
                <option value="Onsite">Onsite</option>
                <option value="Remote">Remote</option>
                </select>
                <select value={workTypeFilter} onChange={(e) => setWorkTypeFilter(e.target.value)}>
                <option value="">Work Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Intern">Intern</option>
                {/* Add more options as needed */}
                </select>
            </div>

            {/* Job list */}
            <div className="job-list">
                {jobList.filter(filterJobs).map((job) => (
                <div key={job.id} className="job-card">
                    <h2>{job.title}</h2>
                    <p>{job.company} - {job.location}</p>
                    <p>Type: {job.type}</p>
                    <p>Work Type: {job.workType}</p>
                    <p>Team: {job.team} - Department: {job.department}</p>
                    {/* Add more job details as needed */}
                </div>
                ))}
            </div>
            </div>
        </Container>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default JobListPage;
