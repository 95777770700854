import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const TicketFilters = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [team, setTeam] = useState([]);
  const [source, setSource] = useState([]);
  const [category, setCategory] = useState([]);
  const [mobile, setMobile] = useState('');
  const [name, setName] = useState('');

  const handleAssignedToChange = (event) => {
    setAssignedTo(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleSourceChange = (event) => {
    setSource(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleFilterClick = () => {
    // Perform filtering logic based on selected filter options
    // You can update your state or call a filtering function here
    console.log('Filtering...');
  };

  return (
    <div>
      {/* Button to toggle the visibility of filters */}
      <Button 
        variant="contained" 
        color="primary" 
        style={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={toggleFilterVisibility}
      >
        {isFilterVisible ? 'Hide Filters' : 'Show Filters'}
      </Button>

      {/* Drawer containing the filters */}
      <Drawer anchor="right" open={isFilterVisible} onClose={toggleFilterVisibility}>
        {/* Add your filter components here */}
        <div style={{ width: '450px', padding: '16px' }}>
          {/* Your filter components go here */}
          <Select
            multiple
            value={assignedTo}
            onChange={handleAssignedToChange}
            label="Assigned To"
            fullWidth
          >
            <MenuItem value="user1">User 1</MenuItem>
            <MenuItem value="user2">User 2</MenuItem>
            {/* Add more options as needed */}
          </Select>

          <Select
            multiple
            value={team}
            onChange={handleTeamChange}
            label="Team"
            fullWidth
          >
            <MenuItem value="team1">Team 1</MenuItem>
            <MenuItem value="team2">Team 2</MenuItem>
            {/* Add more options as needed */}
          </Select>

          <Select
            multiple
            value={source}
            onChange={handleSourceChange}
            label="Source"
            fullWidth
          >
            <MenuItem value="source1">Source 1</MenuItem>
            <MenuItem value="source2">Source 2</MenuItem>
            {/* Add more options as needed */}
          </Select>

          <Select
            multiple
            value={category}
            onChange={handleCategoryChange}
            label="Category"
            fullWidth
          >
            <MenuItem value="category1">Category 1</MenuItem>
            <MenuItem value="category2">Category 2</MenuItem>
            {/* Add more options as needed */}
          </Select>

          <TextField
            label="Mobile"
            variant="outlined"
            value={mobile}
            onChange={handleMobileChange}
            fullWidth
            style={{ marginTop: '16px' }}
          />

          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            fullWidth
            style={{ marginTop: '16px' }}
          />
          {/* Add more filter components as needed */}
          {/* Filter button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterClick}
            fullWidth
            style={{ marginTop: '16px' }}
          >
            Filter
          </Button>
        </div>
      </Drawer>
      
      {/* The rest of your content goes here */}
      {/* <div>
        <p>Main Content Here</p>
      </div> */}
    </div>
  );
};

export default TicketFilters;
