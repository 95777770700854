import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
// import Notes from '../../pages/dashboard/Notes';
import Pricing from './Pricing';
import GettingStarted from './GettingStarted';
import FAQs from './Faqs';
import theme from '../containers/theme';
import JoinWaitlistDialog from './website/dialogs/join-waitlist';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function WebsiteMenu() {
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="About" {...a11yProps(1)} />
          <Tab label="Pricing" {...a11yProps(2)} />
          {/* <Tab label="Pricing" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Unlock Talent,
          <br/>
          Unleash Success with <b>FreeHire!</b>
      {/* Capturing <b>Brilliance</b>,
      One <b>Note</b> at a Time! */}
      </Typography>
      <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {/* <Button variant="contained" style={{backGroundcolor: '#D90700'}}>Join Wailist</Button> */}
              <Button variant="contained" onClick={handleClickOpen} style={{backGroundcolor: '#D90700'}}>
              Join Wailist
              </Button>
              <JoinWaitlistDialog open={open} onClose={handleClose} />
              {/* <Button variant="outlined">Secondary action</Button> */}
            </Stack>
          <GettingStarted/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Typography mt={2} variant="h4" component="h4">
            Introduction about Free Hire:
        </Typography>
        <p>
        FreeHire is a cutting-edge talent acquisition platform that revolutionizes the way companies find, attract, and hire top-tier talent.
        <br/>
        Our innovative approach harnesses the power of AI and data-driven insights to streamline the recruitment process, saving time and resources while ensuring the perfect fit for every role.
        <br/>
        With FreeHire, hiring managers can access a diverse pool of qualified candidates, make informed decisions, and build high-performing teams that drive success.
        </p>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
          FAQs
          <FAQs/>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        {/* Contact Us */}
        <Pricing/>
      </CustomTabPanel>
      <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Join Waitlist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To use to this website, please enter your email address here. We
            will send you more details.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Join</Button>
        </DialogActions>
      </Dialog>
      </>
    </Box>
  );
}
