import axios from 'axios';

const HTTP_STATUS_CODES = {
  '200': 'OK',
  '201': 'Created',
  '202': 'Accepted',
  '203': 'Non-Authoritative Information',
  '204': 'No Content',
  '205': 'Reset Content',
  '206': 'Partial Content',
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Found',
  '303': 'See Other',
  '304': 'Not Modified',
  '305': 'Use Proxy',
  '307': 'Temporary Redirect',
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Failed',
  '413': 'Request Entity Too Large',
  '414': 'Request-URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Requested Range Not Satisfiable',
  '417': 'Expectation Failed',
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported'
};

const errorHandlingForApiResponse = function (response) {
  console.log('response http service', response);
  if (response.status === 200 || response.status === 201) {
    return {
      type: 'success',
      title: HTTP_STATUS_CODES[response.status],
      message: 'Success',
      data: response?.data
    }
  }
  else {
    return {
      type: 'error',
      title: 'Error',
      message: 'Request Failed',
      data: response?.data
    }
  }
}

const onErrorResponse = function (error) {
  console.log(error);
  if (error.response && error.response.status === 400) {
    return {
      type: 'error',
      title: 'Error',
      message: error.response.data.error,
      data: null
    }
  } else {
    return {
      type: 'error',
      title: 'Error',
      message: 'Request Failed',
      data: null
    }
  }
}

const client = axios.create({});

const httpService = function (url) {
  return client(url)
  .then(errorHandlingForApiResponse)
  .catch(onErrorResponse);
}

export default httpService;
