export const leads = [
    {
        key: 'create_lead',
        label: 'Create Lead',
        icon: 'fa fa-plus',
        path: '/leads/create',
        permission: 'create_lead'
    },
    {
        key: 'view_lead',
        label: 'Leads',
        icon: 'fa fa-list',
        path: '/leads',
        permission: 'view_lead'
    }
];

export const tickets = [
    {
        key: 'create_ticket',
        label: 'Create Ticket',
        icon: 'fa fa-plus',
        path: '/tickets/create',
        permission: 'create_ticket'
    },
    {
        key: 'view_ticket',
        label: 'Tickets',
        icon: 'fa fa-list',
        path: '/tickets',
        permission: 'view_ticket'
    }
];

export const top_header_menu = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        icon: 'fa fa-plus',
        path: '/dashboard',
        permission: 'dashboard',
        link: '/crm/dashboard'
    },
    {
        key: 'leads',
        label: 'Leads',
        icon: 'fa fa-plus',
        path: '/leads',
        permission: 'leads',
        link: '/crm/leads'
    },
    {
        key: 'tickets',
        label: 'Tickets',
        icon: 'fa fa-list',
        path: '/tickets',
        permission: 'tickets',
        link: '/crm/tickets'
    },
    {
        key: 'contacts',
        label: 'Contacts',
        icon: 'fa fa-list',
        path: '/contacts',
        permission: 'contacts',
        link: '/crm/contacts'
    },
    {
        key: 'accounts',
        label: 'Accounts',
        icon: 'fa fa-list',
        path: '/accounts',
        permission: 'accounts',
        link: '/crm/accounts'
    }
];