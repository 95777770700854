import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import HomePage from "../pages/HomePage";
import Dashboard from '../pages/dashboard/Dashboard';
import { Layout as DashboardLayout } from '../pages/dashboard/Layout';
import Login from "../pages/auth/Login";
import CheckAccount from '../pages/auth/CheckAccount';
import NotFoundPage from "../containers/common/NotFoundPage";
import ForgotPassword from "../pages/auth/ForgotPassword";
import About from "../pages/dashboard/About";
import TnC from "../pages/static/TnC";
import Privacy from "../pages/static/Privacy";
import Refunds from "../pages/static/Refund";
import SampleJob from "../pages/static/SampleJob";
import SignUp from "../pages/auth/Signup";
import ScoreCalculator from "../pages/tools/ScoreCalculator";
// import {NotFound as NotFoundPage} from '../pages/404';
import ReportPage from '../pages/dashboard/Home';
import { Layout as Home2 } from '../pages/dashboard/Home2';
// import Home2 from '../pages/dashboard/Home2';
import { Leads } from '../pages/dashboard/leads/Leads';
import { Tickets } from '../pages/dashboard/tickets/Tickets';
// import {LoginPage} from '../pages/dashboard/LoginPage';
import {LoginPage} from '../pages/auth/LoginPage';
import JobListPage from "../pages/static/JobListPage";
import { Contacts } from "../pages/dashboard/contacts/Contacts";
import { Accounts } from "../pages/dashboard/accounts/Accounts";

// import { fakeAuthProvider } from "../auth";

// import {isAuthenticated} from "../shared/AuthClass";

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     AuthClass.isAuthenticated() === true && AuthClass.isAuthorized() === true ?
//       <Component {...props} />
//       : AuthClass.isAuthenticated() === true && AuthClass.isAuthorized() !== true ? <Navigate to='/auth/not-authorized' />
//       : <Navigate to='/auth/login' />
//   )} />
// )

const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const routes = () =>
  <Routes>
    {/* <Route path="/" element={<PrivateRoute Component={HomePage} />} /> */}
    <Route
        path="/dashboard"
        isAuthenticated={true}
        element={
            <PrivateRoute><HomePage /></PrivateRoute>
        }
    />
    {/* <Route path={`/login`} exact Component={Login} /> */}
    <Route path={`/:account`} exact Component={CheckAccount} />
    <Route path={`/:account/crm/login`} exact Component={Login} />
    <Route path={`/auth/login`} exact Component={LoginPage} />
    <Route path={`/sign-up`} exact Component={SignUp} />
    <Route path={`/tools/calculate-score`} exact Component={ScoreCalculator} />
    <Route path={`/forgot-password`} exact Component={ForgotPassword} />
    <Route path={`/`} exact Component={HomePage} />
    <Route path={`/dash`} exact Component={Dashboard} />
    <Route path={`/dash3`} exact Component={Home2} />
    <Route path={`/sign-in`} exact Component={LoginPage} />
    <Route path="/dash2" element={<DashboardLayout children={ReportPage} />} />
    <Route path={`/p/about`} exact Component={About} />
    <Route path={`/p/tnc`} exact Component={TnC} />
    <Route path={`/p/privacy`} exact Component={Privacy} />
    <Route path={`/p/refunds`} exact Component={Refunds} />
    <Route path={`/p/job-sample`} exact Component={SampleJob} />
    <Route path={`/p/job-list`} exact Component={JobListPage} />
    {/* CRM Routes */}
    <Route path={`/crm/dashboard`} exact Component={Home2} />
    <Route path={`/crm/leads`} exact Component={Leads} />
    <Route path={`/crm/tickets`} exact Component={Tickets} />
    <Route path={`/crm/contacts`} exact Component={Contacts} />
    <Route path={`/crm/accounts`} exact Component={Accounts} />
    <Route path="/auth/not-authorized" Component={NotFoundPage} />
    <Route path="/404" Component={NotFoundPage} />
    { /* Finally, catch all unmatched routes */}
    <Route path="*" Component={NotFoundPage} />
  </Routes>;
export default routes
