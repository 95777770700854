import React from 'react';
// import { useRoutes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
// import theme from '../src/containers/theme';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from './theme';
// import 'simplebar-react/dist/simplebar.min.css';
// import App from './App';
import { routes } from './routes/routes2';
import Routes from "./routes/routes";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  colorPreset: 'green',
  contrast: 'high'
});

class ReactApp extends React.Component {

  componentDidMount() {
    //do nothing
  }
  
  render() {
    return (
      <Router>
        <Routes />
      </Router>
    );
  }
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ReactApp />
  </ThemeProvider>
);
// ReactDOM.render(<ReactApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
