import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
// import { Logo } from 'src/components/logo';
// import logoLt from '../../logo_lt.png';
import AccountMenu from '../../containers/common/AccountMenu';
import { leads, top_header_menu } from './menu/constants';

const TOP_NAV_HEIGHT = 64;

export function TopNav () { 

  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu.key);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log('handleClick', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
  <Box
    component="header"
    sx={{
      backgroundColor: 'neutral.900',
      color: 'common.white',
      position: 'fixed',
      width: '100%',
      zIndex: (theme) => theme.zIndex.appBar
    }}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        minHeight: TOP_NAV_HEIGHT,
        px: 3
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={3}
      >
        <Box
          component={RouterLink}
          to="/dash2"
          sx={{
            display: 'inline-flex',
            height: 24,
            width: 24
          }}
        >
          {/* <logoLt /> */}
          {/* <img src={logoLt} style={{maxHeight: '65px'}} /> */}
        </Box>
        {top_header_menu && top_header_menu.length > 0 && top_header_menu.map((menu, index) => (
          <Button
            key={menu?.key}
            component={RouterLink}
            to={menu?.link}
            onClick={() => handleMenuClick(menu)}
            sx={{
              color: 'inherit',
              fontWeight: 'normal',
              '&:hover': {
                color: 'inherit'
              },
              // Conditional styling for the selected menu
              ...(selectedMenu === menu.key && {
                fontWeight: 'bold',
                color: 'white', // Change to your preferred color for highlighting
              }),
            }}
          >
            {menu?.label}
          </Button>
        ))}
        {/* <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? 'true' : undefined}
          // onClick={handleClick}
        >
          Dashboard
        </Button>
        <Button
          id="lead-button"
          aria-controls={open ? 'leads-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Leads
        </Button> */}
        {/* <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose} key='profile'>Profile</MenuItem>
          <MenuItem onClick={handleClose} key='my_account'>My account</MenuItem>
          <MenuItem onClick={handleClose} key='logout'>Logout</MenuItem>
        </Menu> */}
        <Menu
          id="leads-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lead-button',
          }}
        >
          {leads && leads.length > 0 && leads.map((lead, index) => (
            <MenuItem onClick={handleClose} key={lead?.key}>{lead?.label}</MenuItem>
          ))}
        </Menu>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
          {/* See Pro Version */}
          <AccountMenu />
        
        {/* <Avatar
          src="/assets/avatars/avatar-chen-simmons.jpg"
          variant="rounded"
        /> */}
      </Stack>
    </Stack>
  </Box>
)
};
