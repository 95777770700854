import { useParams } from 'react-router-dom';
import {account_slug} from '../../constants/accounts';

export default function CheckAccount() {
    const { account } = useParams();
    console.log('account', account);
    let slug = window.localStorage.getItem('account');
    if(slug !== account) {
        window.localStorage.clear('account');
    }
    if(!account_slug.includes(account)) {
        window.location.href = "/404";
    } else {
        window.localStorage.setItem('account', account);
        window.location.href = `/${account}/crm/login`;
    }
}