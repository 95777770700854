export const pricing = [{
    "id": 1,
    "title": "Basic",
    "price": 99,
    "currency": "₹",
    "features": ["5 Job posting /mo", "1 User", "1 GB Storage", "Email Support"]
}, {
    "id": 2,
    "title": "Pro",
    "price": 499,
    "currency": "₹",
    "features": ["25 Job posting /mo", "5 Users", "10 GB Storage", "Priority Email Support"]
}, {
    "id": 3,
    "title": "Enterprise",
    "price": 999,
    "currency": "₹",
    "features": ["100 Job posting /mo", "100 Users", "100 GB Storage", "Phone & Email Support"]
}]
