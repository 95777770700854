import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import BasicMenu from '../containers/common/Navigation';
// import AccountMenu from '../containers/common/AccountMenu';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import BasicTabs from '../containers/common/TabMenu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import WebsiteMenu from '../WebsiteMenu';
import Footer from '../Footer';
// import Footer2 from '../Footer2';
import logo from '../../freehire.png';

import Divider from '@mui/material/Divider';
// or
// import { Divider } from '@mui/material';
// import ProTip from '../../containers/common/ProTip';
// import Link from '../../containers/common/Link';
// import { Navigate } from 'react-router-dom';
// import Copyright from '../../containers/common/Copyright';

function Privacy() {
  return (
    <>
    <Container maxWidth="lg">
        <Box sx={{ my: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography component="h5">
            <img src={logo} style={{maxHeight: '35px'}} alt="" />
          </Typography>
          {/* <WebsiteMenu/> */}
        </Grid>
        {/* <Grid item xs={2}>
          <Tooltip title="Login">
              <Button variant="outlined">
                  Login
              </Button>
          </Tooltip>
        </Grid> */}
        </Grid>
        </Box>
        <Divider/>
      <Box sx={{ my: 2 }}>
        {/* <BasicMenu/> */}
        <Container maxWidth="md">
        <div className="privacy-policy-page">
          <h1>Privacy Policy</h1>
          <p>Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website, [Your Website URL], and other sites we own and operate.</p>
          
          <h2>Information We Collect</h2>
          <p>We only collect information about you if we have a reason to do so – for example, to provide our services, to communicate with you, or to make our services better.</p>
          <p>We collect this information from three sources: if and when you provide information to us, automatically through operating our services, and from outside sources. </p>
          
          <h2>How We Use Information</h2>
          <p>We use the information we collect to provide our services, communicate with you, improve our services, and to personalize your experience.</p>
          
          <h2>Sharing of Information</h2>
          <p>We may share information about you in certain situations, including with your consent, with our service providers, to comply with legal obligations, to protect and defend our rights and property, and in connection with a business transfer.</p>
          
          <h2>Security</h2>
          <p>We take appropriate measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.</p>
          
          <h2>Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          
          <h2>Contact Us</h2>
          <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
          <p>[Your Contact Information]</p>
        </div>
          </Container>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default Privacy;
