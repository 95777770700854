import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import Button from '@mui/material/Button';
// import { Helmet } from 'react-helmet-async';
import {
    Avatar,
    Box,
    Container,
    Stack,
    SvgIcon,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import SubMenu from '../SubMenu';
// import { subDays, subHours, subMinutes } from 'date-fns';
import ShoppingBagIcon from '@heroicons/react/24/solid/ShoppingBagIcon';
import ShoppingCartIcon from '@heroicons/react/24/solid/ShoppingCartIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
// import { OverviewKpi } from '../../../sections/overview/overview-kpi';
// import { OverviewLatestCustomers } from '../../../sections/overview/overview-latest-customers';
import { OverviewSummary } from '../../../sections/overview/overview-summary';

// import PropTypes from 'prop-types';
import { Footer } from '../footer';
import { TopNav } from '../TopNav';
import LeadFilterComponent from './LeadFilter';

const SIDE_NAV_WIDTH = 73;
const TOP_NAV_HEIGHT = 64;

// const now = new Date();

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: TOP_NAV_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

export const Leads = (props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TopNav />
      {/* <SideNav /> */}
      <LayoutRoot>
        <LayoutContainer>
            <>
            {/* <Helmet>
                <title>
                    Overview | Carpatin Free
                </title>
            </Helmet> */}
            <Box
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
                >
                <LeadFilterComponent />
                <Container maxWidth="xl">
                    <Stack spacing={3}>
                    <div>
                        <Typography variant="h4">
                        Leads
                        <Button 
                          variant="contained" 
                          color="primary"
                          style={{ position: 'fixed', right: 16 }}
                        >
                          Create Lead
                        </Button>
                        </Typography>
                    </div>
                    <div>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <ShoppingBagIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Applications'
                            value='5610'
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <ShoppingCartIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Products'
                            value='23'
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          md={4}
                        >
                          <OverviewSummary
                            icon={
                              <Avatar
                                sx={{
                                  backgroundColor: 'primary.main',
                                  color: 'primary.contrastText',
                                  height: 56,
                                  width: 56
                                }}
                              >
                                <SvgIcon>
                                  <CurrencyDollarIcon />
                                </SvgIcon>
                              </Avatar>
                            }
                            label='Transactions'
                            value='1942'
                          />
                        </Grid>
                        <Grid xs={12}>

                        <SubMenu/>
                        <Root sx={{ width: 1200, maxWidth: '100%' }}>
                          <table aria-label="custom pagination table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Source</th>
                                <th>Type</th>
                                <th>Assigned To</th>
                                <th>Created</th>
                                <th>Due Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                              ).map((row) => (
                                <tr key={row.name}>
                                  <td>{row.name}</td>
                                  <td style={{ width: 120 }} align="right">
                                    {row.calories}
                                  </td>
                                  <td style={{ width: 120 }} align="right">
                                    {row.fat}
                                  </td>
                                  <td>{row.name}</td>
                                  <td>{row.name}</td>
                                  <td>{row.name}</td>
                                  <td>{row.name}</td>
                                  <td>{row.name}</td>
                                  <td>{row.name}</td>
                                </tr>
                              ))}

                              {emptyRows > 0 && (
                                <tr style={{ height: 34 * emptyRows }}>
                                  <td colSpan={3} aria-hidden />
                                </tr>
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <CustomTablePagination
                                  rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                                  colSpan={9}
                                  count={rows.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  slotProps={{
                                    select: {
                                      'aria-label': 'rows per page',
                                    },
                                    actions: {
                                      showFirstButton: true,
                                      showLastButton: true,
                                    },
                                  }}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                              </tr>
                            </tfoot>
                          </table>
                        </Root>
                        </Grid>
                        <Grid xs={12}>
                          
                        </Grid>
                      </Grid>
                    </div>
                    </Stack>
                </Container>
            </Box>
            </>
            {/* {children} */}
          <Footer />
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0),
  createData('KitKat', 518, 26.0),
  createData('Lollipop', 392, 0.2),
  createData('Marshmallow', 318, 0),
  createData('Nougat', 360, 19.0),
  createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const blue = {
  50: '#F0F7FF',
  200: '#A5D8FF',
  400: '#3399FF',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Root = styled('div')(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
  }
  `,
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }
  `,
);