import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import BasicMenu from '../containers/common/Navigation';
// import AccountMenu from '../containers/common/AccountMenu';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// import BasicTabs from '../containers/common/TabMenu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import WebsiteMenu from '../WebsiteMenu';
import Footer from '../Footer';
// import Footer2 from '../Footer2';
import logo from '../../freehire.png';

import Divider from '@mui/material/Divider';
// or
// import { Divider } from '@mui/material';
// import ProTip from '../../containers/common/ProTip';
// import Link from '../../containers/common/Link';
// import { Navigate } from 'react-router-dom';
// import Copyright from '../../containers/common/Copyright';

function Refunds() {
  return (
    <>
    <Container maxWidth="lg">
        <Box sx={{ my: 1 }}>
        <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography component="h5">
            <img src={logo} style={{maxHeight: '35px'}} alt="" />
          </Typography>
          {/* <WebsiteMenu/> */}
        </Grid>
        {/* <Grid item xs={2}>
          <Tooltip title="Login">
              <Button variant="outlined">
                  Login
              </Button>
          </Tooltip>
        </Grid> */}
        </Grid>
        </Box>
        <Divider/>
      <Box sx={{ my: 2 }}>
        {/* <BasicMenu/> */}
        <Container maxWidth="md">
        <div className="payment-and-refund-page">
          <h1>Payment & Refund Policy</h1>
          <h2>Payment</h2>
          <p>We offer premium features and services for a fee. Payment for these services will be collected through our online payment system.</p>
          <p>By purchasing premium features or services, you agree to pay the specified fees and abide by our refund policy.</p>
          
          <h2>Refund</h2>
          <p>We strive to provide the best experience possible. However, if you are not satisfied with our premium features or services, we offer a refund policy under the following conditions:</p>
          <ul>
            <li>Refunds will only be issued within [number] days of the original purchase.</li>
            <li>To request a refund, please contact our support team with your purchase details.</li>
            <li>Refunds will be issued to the original payment method used for the purchase.</li>
            <li>Refunds may take [number] days to process.</li>
            <li>We reserve the right to deny refunds for abuse of our premium features or services.</li>
          </ul>
        </div>
          </Container>
      </Box>
    </Container>
    <Footer/>
    </>
  );
}

export default Refunds;
