import httpService from './http.service';

let BASEURL;
let LOCALBASEURL;

const DOMAIN = 'localhost:3002/';

if(process.env.NODE_ENV === "development"){
  BASEURL = process.env.REACT_APP_BASEURL;
  LOCALBASEURL = process.env.REACT_APP_LOCALBASEURL;
} else if(process.env.NODE_ENV === "production"){
  BASEURL = process.env.REACT_APP_BASEURL;
  LOCALBASEURL = process.env.REACT_APP_LOCALBASEURL;
}


async function login(email, password) {
  var hostName = window.location.hostname;
  let baseUrl = hostName.indexOf("localhost") > -1
    || hostName.indexOf(DOMAIN) > -1
    || hostName.indexOf("localhost") > -1 ?
    LOCALBASEURL :
    BASEURL;
  const api_res = await httpService({
      url: baseUrl + '/v1/login/create-token',
      method: "post",
      // headers: {
      //   "method": 'API',
      // },
      data: {email, password},
  });
  console.log('api_res', api_res);
  return api_res;
};

const authService = {
  login,
};

export default authService;