import cookie from 'react-cookies';
import { useState } from "react";
import { Auth } from "aws-amplify";

// Sets user details in localStorage

function AppAuth() {
    const [isAuthenticated, setAuth] = useState(false);
    return isAuthenticated;
}

// checks if the user is authenticated
function getToken() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('PhlebEx_expires_at'));
    // return new Date().getTime() < expiresAt;
    if (new Date().getTime() < expiresAt) {
      let token = cookie.load('PhlebEx_dash_token') ? cookie.load('PhlebEx_dash_token') : null;
      // return "Bearer " + token;
      return token;
    } else {
      Auth.currentSession().then(function (session) {
        if (!session || !session.idToken || !session.idToken.jwtToken) {
          console.error("****************session expired******************");
          if (window.location.pathname.indexOf("login") === -1) {
            window.location = "/login";
          }
          return "Bearer " + null;
        } else {
          console.error("********token_refreshed**********")
          let expiresAt = JSON.stringify((2700 * 1000) + new Date().getTime());
          cookie.save('PhlebEx_dash_token', session.idToken.jwtToken, { path: '/' })
          localStorage.setItem('PhlebEx_expires_at', expiresAt);
          // return "Bearer " + session.idToken.jwtToken;
          return session.idToken.jwtToken;
        }
      }, (err) => {
        console.error("Error occurred while login: " + err);
        if (window.location.pathname.indexOf("login") === -1) {
          window.location = "/login";
        }
        return "Bearer " + null;
      });
    }
  }

function setSession(data) {
    if(data) {
        let expiresAt = JSON.stringify((27000 * 1000) + new Date().getTime());
        cookie.save('React_dash_token', data, { path: '/' })
        cookie.save('access_token', data, { path: '/' });
        // localStorage.setItem('sessionInfo', data.loginOutput);
        localStorage.setItem('React_expires_at', expiresAt);
        localStorage.setItem('last_loggedin_time', new Date());
        return true;
    } else {
        return false;
    }
};
  
function isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem('React_expires_at'));
    return new Date().getTime() < expiresAt;
};

function isAuthorized() {

    return true;
    // let expiresAt = JSON.parse(localStorage.getItem('React_expires_at'));
    // return new Date().getTime() < expiresAt;
};

let AuthClass = {
    AppAuth,
    getToken,
    setSession,
    isAuthenticated,
    isAuthorized,
};

export default AuthClass;

